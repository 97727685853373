import React from 'react'
import EnFlag from '../images/flags/en.png'
import FiFlag from '../images/flags/fi.png'
import EtFlag from '../images/flags/et.png'
import { Link } from 'gatsby'

interface Props {
  selectedLanguage: string
}

export const SwitchLanguage = ({ selectedLanguage }: Props) => {
  let languages = [
    { language: 'en', path: '/en', image: EnFlag, alt: 'English' },
    { language: 'fi', path: '/', image: FiFlag, alt: 'Suomi' },
    { language: 'et', path: '/et', image: EtFlag, alt: 'Eesti Keel' },
  ]

  let currentLanguage = languages.find((x) => x.language === selectedLanguage)
  let filteredOptions = languages.filter((x) => x.language !== selectedLanguage && x.language !== 'et')
  return (
    <>
      <hr />
      <div className="widget-box">
        <strong className="title-sidebar">Languages</strong>
        <ul className="wpm-language-switcher switcher-dropdown">
          <li className="item-language-main item-language-en">
            <span>
              <img src={currentLanguage.image} alt={currentLanguage.alt} />
            </span>
            <ul className="language-dropdown">
              {filteredOptions.map((option, key) => (
                <li key={key}>
                  <Link to={option.path}>
                    <img src={option.image} alt={option.alt} />
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </div>
    </>
  )
}
