import React from 'react'

/*
	This component creates opacity transition based on the shouldChange prop changing.
 */

interface Props {
  shouldChange: string
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>
}

const FadeTransition: React.FC<Props> = ({ shouldChange, children }) => {
  const prevChildren = React.useRef<React.ReactNode>()
  const currentChildren = React.useRef<React.ReactNode>()
  const currentContainer = React.useRef<HTMLDivElement>()

  const [isAnimating, setIsAnimating] = React.useState<boolean>(false)

  const currentKey = React.useRef<string>(null)

  if (currentKey?.current !== null && currentKey.current !== shouldChange && currentChildren?.current && currentChildren.current !== children) {
    currentKey.current = shouldChange
    prevChildren.current = currentChildren.current
    setIsAnimating(true)
    setTimeout(() => setIsAnimating(false), 200)
  } else {
    currentChildren.current = children
    currentKey.current = shouldChange
  }

  return (
    <div style={{ width: '100%', height: '100%', flexGrow: 1, position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          transition: '0.2s',
          opacity: isAnimating ? 0 : 1,
          flexGrow: 1,
          flexDirection: 'column',
        }}
        ref={currentContainer}
      >
        {isAnimating ? prevChildren.current : children}
      </div>
    </div>
  )
}

export default FadeTransition
