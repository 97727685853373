import React, { useEffect, useState } from 'react'
import '../assets/css/app.scss'
import Logo from '../images/logo.svg'
import { SwitchLanguage } from '../components/switch-language'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import FadeTransition from '../components/fade-transition'

function getLinkHref(linkHrefElement, language) {
  if( linkHrefElement.startsWith("http") ) {
    return linkHrefElement
  }
  if( language === 'fi') {
    return `/${linkHrefElement}`
  }
  return `/${language}/${linkHrefElement}`
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ location, children, pageContext }) {
  const data = useStaticQuery(graphql`
    {
      allSanitySiteSettings {
        nodes {
          id
          title {
            en
            et
            fi
          }
          copyrightNotice {
            en
            et
            fi
          }
          _rawTopMenu
          _rawTopMenuButton
          _rawBottomMenu
          _rawBlogDescription
        }
      }
    }
  `)

  let settings = data?.allSanitySiteSettings?.nodes[0]

  let language = 'fi'
  let splitPath = location.pathname.split('/')
  if (splitPath.length > 1) {
    if (splitPath[1] === 'en' || splitPath[1] === 'fi' || splitPath[1] === 'et') {
      language = splitPath[1]
    }
  }
  const [position, setPosition] = useState('position')
  const [visible] = useState(true)
  const [headerVisible, setHeaderVisible] = useState(false)

  //   let scrollY = useScrollPosition(40) | position
  useEffect(() => {
    if (visible) {
      const offHeight = window.pageYOffset
      const offsetHeight = offHeight ? offHeight : position
      if (offHeight !== position) {
        setPosition(offsetHeight)
      }
      if (position >= 40 && headerVisible === false) {
        setHeaderVisible(true)
      } else if (position < 40 && headerVisible === true) {
        setHeaderVisible(false)
      }
    }
  }, [visible, headerVisible, position])

  function logit() {
    setPosition(window.pageYOffset)
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', logit)
    }

    watchScroll()
    return () => {
      window.removeEventListener('scroll', logit)
    }
  })

  const showMenu = () => {
    let element = document.querySelector('html')

    if (element.classList.contains('open-nav')) {
      element.classList.remove('open-nav')
    } else {
      element.classList.add('open-nav')
    }
  }
  const logoLink = language === 'fi' ? '/' : `/${language}`
  return (
    <div className="layout">
      <Helmet htmlAttributes={{ lang: language }}>
        <title>{settings.title && settings.title[language]}</title>
        <body className={pageContext.limit && 'archive'} />
      </Helmet>

      <header className={`header ${headerVisible && 'header-active'}`}>
        <div className="container container-md d-md-flex justify-content-between align-items-center">
          <div className="logo-holder">
            <Link className="site-logo" to={logoLink} title="Ninjou extrapalvelu">
              <img className="logo-main" src={Logo} alt="Ninjou extrapalvelu" />
            </Link>
          </div>
          <div className="languages-mob d-md-none">
            <SwitchLanguage selectedLanguage={language} />
          </div>
          <button className="menu-btn bt-menu-trigger js-toggle-nav open-btn d-block d-md-none" onClick={showMenu}>
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
          <div className="navigation-cont d-md-flex align-items-center">
            <div className="menu-main-menu-container">
              <ul id="menu-main-menu" className="navigation">
                {settings._rawTopMenu &&
                  settings._rawTopMenu.map((item, key) => (
                    <li key={key} className="menu-item menu-item-type-post_type menu-item-object-page menu-item-100">
                      <a href={getLinkHref(item.linkHref[language], language)}>{item.linkText[language]}</a>
                    </li>
                  ))}
                {settings._rawTopMenuButton && (
                  <li className="custom-link menu-item menu-item-type-custom menu-item-object-custom menu-item-8">
                    <a href={settings._rawTopMenuButton.linkHref[language]}>{settings._rawTopMenuButton.linkText[language]}</a>
                  </li>
                )}

              </ul>
            </div>
            <div className="text-center languages d-none d-lg-block">
              <SwitchLanguage selectedLanguage={language} />
            </div>
          </div>
        </div>
      </header>

      <FadeTransition shouldChange={location.pathname}>{children}</FadeTransition>

      <footer id="footer" className="footer">
        <div className="container d-md-flex flex-row-reverse justify-content-between align-items-center">
          <div className="menu-footer-menu-container">
            <ul id="menu-footer-menu" className="navigation-footer d-flex justify-content-center">
              {settings._rawBottomMenu &&
                settings._rawBottomMenu.map((item, key) => (
                  <li key={key} id="menu-item-160" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-160">
                    <a href={item.linkHref[language]}>{item.linkText[language]}</a>
                  </li>
                ))}
            </ul>
          </div>
          <div className="text text-center">
            <p>{settings.copyrightNotice && settings.copyrightNotice[language]}</p>
          </div>
        </div>
      </footer>
    </div>
  )
}
